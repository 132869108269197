<template>
  <div id="test-page">
    <h1>Test Page</h1>
    <a href="/openapi.json" target="_blank">API Spec</a>
    <div class="container">
      <form>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Client</label>
          <div class="col-sm-10">
            <input
              v-model="client"
              type="text"
              class="form-control"
              placeholder="Client"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">User Role</label>
          <div class="col-sm-5">
            <select ref="userRole" class="form-control" v-model="userRole">
              <option value="" disabled>User Role</option>
              <option value="agent">Agent</option>
              <option value="underwriter" selected>Underwriter</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Expires</label>
          <div class="col-sm-5">
            <input
              v-model="expires"
              type="text"
              class="form-control"
              placeholder="Expires"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">Quote ID</label>
          <div class="col-sm-5">
            <input
              v-model="quoteId"
              type="text"
              class="form-control"
              placeholder="Quote ID"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-2 col-form-label">API Key</label>
          <div class="col-sm-5">
            <input
              v-model="apiKey"
              type="text"
              class="form-control"
              placeholder="API Key"
            />
          </div>
        </div>
        <a @click="startSession()" class="btn btn-primary btn-lg">
          Test
        </a>
      </form>
      <div class="row">
        <div class="alert alert-danger" role="alert" v-if="errorExists">
          Invalid API Key
        </div>
      </div>
    </div>
    <div id="results" v-if="sessionUrl">
      <h6><span class="badge badge-secondary">Session URL</span></h6>
      <pre class="text-success">
        <code>{{ this.sessionUrl }}</code>
      </pre>
      <pre class="text-muted">
        <code>{{ this.status }}</code>
      </pre>
      <a :href="this.sessionUrl" target="_blank">>></a>
      <iframe id="sessionFrame" :src="sessionUrl" />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'Home',
  props: {
    token: {
      type: String
    }
  },
  data: function () {
    return {
      client: 'hanover',
      userRole: 'underwriter',
      expires: '02/25/2022 1:00:00 AM',
      quoteId: '2906',
      apiKey: '',
      errorExists: false,
      sessionUrl: '',
      status: {},
      host: ''
    }
  },
  methods: {
    startSession: async function () {
      if (!this.apiKey) {
          return;
        }
        const jwt = require('njwt');
        const claims = {
          "client": this.client,
          "role": this.userRole,
          "quote_id": this.quoteId
        }
        const tk = jwt.create(claims, this.apiKey)
        tk.setExpiration(Date.parse(this.expires));
        const token = tk.compact();
        
        await axios.post("/api/init", { token: token }).then(res => {
          this.errorExists = false;
          this.sessionUrl = res.data.url;
          this.status = res.data.status;
      }).catch(() => {
        this.errorExists = true;
        this.sessionUrl = "";
      });
    }
  }
}
</script>

<style lang="scss">
  #sessionFrame {
    width: 100%;
    height: 800px;
  }
  #results {
    margin-top: 50px;
  }
  #test-page {
    margin-top: 25px;
  }
</style>